<template>
  <el-dialog
    customClass="UnitDialogCURD"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-30">{{ dialogTitle }}</h5>
      <vs-divider></vs-divider>
    </span>
    <!-- 弹窗内容 -->
    <el-form class="common-form" :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
      <!-- 表单认证(楼栋名称) -->
      <el-form-item label="单元名称" prop="unitName">
        <el-input v-model="form.unitName"  prefix-icon="el-icon-user" placeholder="请输入单元名称"  autocomplete="off" :disabled="isDisable"></el-input>
      </el-form-item>

      <!-- 表单认证(楼栋编号地址) -->
      <el-form-item label="单元编号" prop="unitNo">
        <el-input v-model="form.unitNo" 
        onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" 
        prefix-icon="el-icon-user" placeholder="请输入单元编号"  autocomplete="off" :disabled="isDisable || operate == 'UPDATE'"></el-input>
      </el-form-item>
    </el-form>

    <!-- 弹窗底部 -->
    <span slot="footer" class="dialog-footer">
      <vs-divider></vs-divider>
      <vs-button
        size="small"
        class="mr-5"
        type="relief"
        color="grey"
        @click="handleClose"
      >
        取 消
      </vs-button>
      <vs-button
        size="small"
        type="relief"
        color="danger"
        class="mr-5 vs-con-loading__container"
        @click="delBtn"
        v-if="isDisable && this.operate == 'DELETE'"
      >
        删除
      </vs-button>
      <vs-button
        size="small"
        type="relief"
        color="primary"
        :disabled="!validateForm"
        class="vs-con-loading__container"
        @click="submitForm('ruleForm')"
        v-if="!isDisable"
      >
        确 定
      </vs-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["dialogFormVisible", "dialogFormData", "dialogFormOpt"],
  data() {
    return {
      reFresh: true,
      dialogTitle: "",
      isVisible: this.dialogFormVisible,
      operate: this.dialogFormOpt,
      isDisable: false,
      isShowCascader: false,
      row: {},
      form: {
        spaceCode: "",
        unitName: "",
        unitNo: "",
        unitID: ""
      },
      rules: {
        unitName: [
          { required: true, message: '请输入单元名称', trigger: 'blur' }
        ],
        unitNo: [
          { required: true, message: '请输入单元编号', trigger: 'blur' },
          { max: 2, message: '不能超过 99', trigger: 'change' }
        ]
      }
    };
  },
  computed: {
    // 监听表单认证状况
    validateForm() {
      let isValid = false;
      switch (this.operate) {
        case "CREATE":
          isValid =
            !this.$cFun.isNullOrEmptyForObJ({
              spaceCode: this.form.spaceCode,
              unitName: this.form.unitName,
              unitNo: this.form.unitNo
            });
          break;
        case "READ":
          break;
        case "UPDATE":
          isValid =
            !this.$cFun.isNullOrEmptyForObJ({
              unitID: this.form.unitID,
              unitName: this.form.unitName
            });
          break;
        case "DELETE":
          break;
      }
      return isValid;
    }
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      this.reFresh = false;
      // row
      this.row = val.row;
      this.form.spaceCode = val.building_Code;
      this.form.unitName = val.unitName;
      this.form.unitNo = val.unitNo;
      this.form.unitID = val.unitID;
      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true;
      });
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.$cFun.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    },
    // 监听父组件传回来的值 - CURD操作值
    dialogFormOpt(val) {
      this.operate = this.dialogFormOpt;
      switch (val) {
        case "CREATE":
          this.dialogTitle = "创建单元";
          this.isDisable = false;
          this.isShowCascader = true;
          break;
        case "READ":
          this.dialogTitle = "查看单元";
          this.isDisable = true;
          this.isShowCascader = false;
          break;
        case "UPDATE":
          this.dialogTitle = "更新单元";
          this.isDisable = false;
          this.isShowCascader = false;
          break;
        case "DELETE":
          this.dialogTitle = "删除单元";
          this.isDisable = true;
          this.isShowCascader = false;
          break;
      }
    }
  },
  methods: {
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget;
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          await this.submit();
        } else {
          return false;
        }
      })
    },
    // 提交事件
    async submit() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el);
      // 判断下操作
      if (this.operate === "CREATE") {
        await this.addUnit();
      } else if (this.operate === "UPDATE") {
        await this.updateUnit();
      }
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 删除事件
    async delBtn() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el, "danger");
      // 提交API删除
      await this.delUnit();
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 添加单元
    async addUnit() {
      try {
        let n = Number(this.form.unitNo);
        let params = {
          spaceCode: this.form.spaceCode,
          unitName: this.form.unitName,
          unitNo:  (n >= 10 && n < 100) ? `${n}` : `0${n}`
        };
        let result = await this.$apis.ODC("添加单元", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 更新单元
    async updateUnit() {
      try {
        let params = {
          unitID: this.form.unitID,
          unitName: this.form.unitName
        };
        let result = await this.$apis.ODC("修改单元", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 删除单元
    async delUnit() {
      try {
        let params = {
          unitID: this.form.unitID,
          unitName: this.form.unitName
        };
        let result = await this.$apis.ODC("删除单元", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 表单校验
    isValidForm() {
      return new Promise((resolve, reject) => {
        const isValid = this.$validator.validateAll();
        isValid
          .then(valid => {
            resolve(valid);
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    // 关闭弹框回调
    handleClose(done, isRefresh = false) {
      this.isVisible = false;
      if (this.isVisible == false) {
        this.emptyData();
        this.$emit("handleDiaLogClose", isRefresh, 'unit', this.row);
      }
    },
    // 清空数据
    emptyData() {
      this.form.spaceCode = "";
      this.form.buildingName = "";
      this.form.buildingNo = "";
    }
  },
  components: {}
};
</script>

<style lang="scss" scope="scope">
.UnitDialogCURD {
  width: 35%;
  min-width: 300px;
}
</style>
