<template>
  <el-dialog customClass="BatchWidth" :visible.sync="isVisible" :destroy-on-close="true" :show-close="true" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="inline-block mr-4 line-h-30">批量生成</h5>
      <el-radio-group v-model="checkRadio" class="inline-block" v-if="!isShowForm">
        <el-radio :label="3">楼栋</el-radio>
        <el-radio :label="2">单元</el-radio>
        <el-radio :label="1">房间</el-radio>
      </el-radio-group>
      <vs-divider></vs-divider>
    </span>
    <!-- 弹窗内容 -->
    <el-form v-show="!isShowForm" class="common-form" :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
      <el-row :gutter="24">
        <!-- 表单认证(批量生成楼栋名称) -->
        <el-col :span="8">
          <el-form-item label="批量生成楼栋名称" prop="buildingName">
            <el-input v-model="form.buildingName" prefix-icon="el-icon-user" placeholder="请输入楼栋名称" autocomplete="off" :disabled="isDisable_build"></el-input>
          </el-form-item>
        </el-col>

        <!-- 表单认证(批量生成楼栋数量) -->
        <el-col :span="8">
          <el-form-item label="批量生成楼栋(起始)">
            <el-input-number class="w-full" v-model="form.buildingStartNo" :min="1" :max="999" :disabled="isDisable_build"></el-input-number>
          </el-form-item>
        </el-col>

        <!-- 表单认证(批量生成楼栋数量) -->
        <el-col :span="8">
          <el-form-item label="批量生成楼栋(截止)">
            <el-input-number class="w-full" v-model="form.buildingEndNo" :min="1" :max="999" :disabled="isDisable_build"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <!-- 表单认证(批量生成单元名称) -->
        <el-col :span="8">
          <el-form-item label="批量生成单元名称" prop="unitName">
            <el-input v-model="form.unitName" prefix-icon="el-icon-user" placeholder="请输入楼栋名称" autocomplete="off" :disabled="isDisable_unit"></el-input>
          </el-form-item>
        </el-col>

        <!-- 表单认证(批量生成单元数量) -->
        <el-col :span="8">
          <el-form-item label="批量生成单元(起始)">
            <el-input-number class="w-full" v-model="form.unitStartNo" :min="1" :max="99" :disabled="isDisable_unit"></el-input-number>
          </el-form-item>
        </el-col>

        <!-- 表单认证(批量生成单元数量) -->
        <el-col :span="8">
          <el-form-item label="批量生成单元(截止)">
            <el-input-number class="w-full" v-model="form.unitEndNo" :min="1" :max="99" :disabled="isDisable_unit"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <!-- 表单认证(批量生成楼层) -->
        <el-col :span="12">
          <el-form-item label="批量生成楼层(起始)">
            <el-input-number class="w-full" v-model="form.floorStartNo" :min="1" :max="99" :disabled="isDisable_house"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="批量生成楼层(截止)">
            <el-input-number class="w-full" v-model="form.floorEndNo" :min="1" :max="99" :disabled="isDisable_house"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <!-- 表单认证(批量生成房间) -->
        <el-col :span="12">
          <el-form-item label="批量生成房间(起始)">
            <el-input-number class="w-full" v-model="form.houseStartNo" :min="1" :max="99" :disabled="isDisable_house"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="批量生成房间(截止)">
            <el-input-number class="w-full" v-model="form.houseStartEnd" :min="1" :max="99" :disabled="isDisable_house"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <!-- 表单认证(批量生成房间) -->
        <el-col :span="12">
          <el-form-item label="批量生成房间-建筑面积" prop="jzmj">
            <el-input v-model="form.jzmj" onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" prefix-icon="el-icon-user" placeholder="请输入建筑面积" autocomplete="off" :disabled="isDisable_house"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="批量生成房间-房屋坐落" prop="fwzl">
            <el-input v-model="form.fwzl" prefix-icon="el-icon-user" placeholder="请输入房屋坐落" autocomplete="off" :disabled="isDisable_house"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 进度条 -->
    <span v-show="isShowForm">
      <div class="mb-3 space-x-4">
        <div class="inline-block"><h6>预期创建:</h6></div>
        <div class="inline-block">楼栋: {{ total.buildNo_E_Count }}</div>
        <div class="inline-block">单元: {{ total.unitNo_E_Count }}</div>
        <div class="inline-block">房屋: {{ total.houseNo_E_Count }}</div>
      </div>
      <div class="mb-3 space-x-4">
        <div class="inline-block"><h6>成功创建:</h6></div>
        <div class="inline-block">楼栋: {{ total.buildNo_S_Count }}</div>
        <div class="inline-block">单元: {{ total.unitNo_S_Count }}</div>
        <div class="inline-block">房屋: {{ total.houseNo_S_Count }}</div>
      </div>
      <el-progress v-if="isShowForm" :text-inside="true" :stroke-width="26" :percentage="total.percentage" status="success"></el-progress>
    </span>

    <!-- 弹窗底部 -->
    <span slot="footer" class="dialog-footer" v-show="!isShowForm">
      <vs-divider></vs-divider>
      <vs-button size="small" class="mr-5" type="relief" color="grey" @click="handleClose">取 消</vs-button>
      <vs-button size="small" type="relief" color="primary" :disabled="!validateForm" class="vs-con-loading__container" @click="submitForm('ruleForm')" v-if="!isDisable">确 定</vs-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['dialogFormVisible', 'dialogFormData', 'dialogFormOpt'],
  data() {
    return {
      reFresh: true,
      dialogTitle: '',
      isVisible: this.dialogFormVisible,
      operate: this.dialogFormOpt,
      isDisable: false,
      isDisable_build: false,
      isDisable_unit: false,
      isDisable_house: false,
      isShowCascader: false,
      isShowForm: false,
      checkRadio: 1,
      form: {
        spaceCode: '',
        buildingName: '号楼',
        buildingStartNo: '',
        buildingEndNo: '',
        unitName: '单元',
        unitStartNo: '',
        unitEndNo: '',
        floorStartNo: '',
        floorEndNo: '',
        houseStartNo: '',
        houseStartEnd: '',
        fwzl: '未知',
        jzmj: '0'
      },
      total: {
        E_Count: 0,
        buildNo_E_Count: 0,
        unitNo_E_Count: 0,
        houseNo_E_Count: 0,
        S_Count: 0,
        buildNo_S_Count: 0,
        unitNo_S_Count: 0,
        houseNo_S_Count: 0,
        percentage: 0
      },
      rules: {
        buildingName: [{ required: true, message: '请输入楼栋名称', trigger: 'blur' }],
        unitName: [{ required: true, message: '请输入单元名称', trigger: 'blur' }],
        jzmj: [
          { required: true, message: '请输入建筑面积', trigger: 'blur' },
          {
            pattern: /^[+-]?(0|([1-9]\d*))(\.\d{1,3})?$/,
            message: '请输入正确建筑面积,最多三位小数',
            trigger: 'blur'
          }
        ],
        fwzl: [{ required: true, message: '请输入房屋坐落', trigger: 'blur' }]
      }
    }
  },
  computed: {
    // 监听表单认证状况
    validateForm() {
      let isValid = false
      isValid = !this.$cFun.isNullOrEmptyForObJ({
        spaceCode: this.form.spaceCode,
        buildingName: this.form.buildingName,
        unitName: this.form.unitName
      })
      return isValid
    }
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      this.reFresh = false
      // row
      this.form.spaceCode = val.village_code
      this.form.buildingName = '号楼'
      this.form.unitName = '单元'
      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true
      })
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.$cFun.changeNavBarShadow(val)
      this.isVisible = this.dialogFormVisible
    },
    checkRadio(val) {
      switch (val) {
        case 3:
          this.isDisable_build = false
          this.isDisable_unit = true
          this.isDisable_house = true
          this.form.fwzl = '未知'
          this.form.jzmj = '0'
          this.$refs.ruleForm.resetFields()
          break
        case 2:
          this.isDisable_build = false
          this.isDisable_unit = false
          this.isDisable_house = true
          this.form.fwzl = '未知'
          this.form.jzmj = '0'
          this.$refs.ruleForm.resetFields()
          break
        case 1:
          this.isDisable_build = false
          this.isDisable_unit = false
          this.isDisable_house = false
          this.$refs.ruleForm.resetFields()
          break
      }
    },
    'total.S_Count'(val) {
      this.total.percentage = parseInt((val / this.total.E_Count) * 100)
    }
  },
  methods: {
    // 批量生成
    async batchData(form) {
      const spaceCode = form['spaceCode']
      const buildingName = form['buildingName']
      const buildingRange = [form['buildingStartNo'], form['buildingEndNo']]
      const unitName = form['unitName']
      const unitRange = [form['unitStartNo'], form['unitEndNo']]
      const floorRange = [form['floorStartNo'], form['floorEndNo']]
      const houseRange = [form['houseStartNo'], form['houseStartEnd']]
      const checkRadio = this.checkRadio

      // 判断创建第几层 3: 楼栋 2: 单元 1: 房间
      let buildingRealRange = _.gte(buildingRange[0], buildingRange[1]) ? buildingRange.reverse() : buildingRange
      let buildNoArr = _.range(buildingRealRange[0], buildingRealRange[1] + 1)
      let unitRealRange = _.gte(unitRange[0], unitRange[1]) ? unitRange.reverse() : unitRange
      let unitNoArr = _.range(unitRealRange[0], unitRealRange[1] + 1)
      let floorRealRange = _.gte(floorRange[0], floorRange[1]) ? floorRange.reverse() : floorRange
      let houseRealRange = _.gte(houseRange[0], houseRange[1]) ? houseRange.reverse() : houseRange
      let floorNoArr = _.range(floorRealRange[0], floorRealRange[1] + 1)
      let houseNoArr = _.range(houseRealRange[0], houseRealRange[1] + 1)
      let floorWithHouseNoArr = [].concat(
        ...floorNoArr.map(n => {
          let floorNo = n >= 10 && n < 100 ? `${n}` : `0${n}`
          let tempHouseNoArr = houseNoArr.map(i => {
            // 只有三位 楼层99 单层9
            // let houseNo = (i >= 10 && i < 100) ? `${i}` : `${i}`;
            let houseNo = i >= 10 && i < 100 ? `${i}` : `0${i}`
            return `${floorNo}${houseNo}`
          })
          return tempHouseNoArr
        })
      )

      // 统计总数
      if (checkRadio <= 3) {
        this.total.buildNo_E_Count = buildNoArr.length
        if (checkRadio <= 2) {
          this.total.unitNo_E_Count = unitNoArr.length * buildNoArr.length
          if (checkRadio <= 1) {
            this.total.houseNo_E_Count = unitNoArr.length * buildNoArr.length * floorWithHouseNoArr.length
          }
        }
      }
      this.total.E_Count = this.total.buildNo_E_Count + this.total.unitNo_E_Count + this.total.houseNo_E_Count

      // 批量创建
      if (checkRadio <= 3) {
        //楼栋
        let tempbuildingArr = _.compact(
          await Promise.all(
            buildNoArr.map(async n => {
              let buildingNo = n >= 100 && n <= 999 ? `${n}` : n >= 10 && n < 100 ? `0${n}` : `00${n}`
              let buildingCode = await this.addBuilding(spaceCode, `${n}${buildingName}`, buildingNo)
              this.total.buildNo_S_Count += 1
              this.total.S_Count += 1
              return buildingCode
            })
          )
        )

        if (checkRadio <= 2) {
          // 单元
          let tempUnitArr = _.reduce(
            await Promise.all(
              unitNoArr.map(async n => {
                let unitNo = n >= 10 && n < 100 ? `${n}` : `0${n}`
                let singleResult = _.compact(
                  await Promise.all(
                    tempbuildingArr.map(async i => {
                      this.total.unitNo_S_Count += 1
                      this.total.S_Count += 1
                      return await this.addUnit(i, `${n}${unitName}`, unitNo)
                    })
                  )
                )
                return singleResult
              })
            ),
            (prev, next) => {
              return prev.concat(next)
            }
          )

          const list = []
          if (checkRadio <= 1) {
            // 房间
            for (const n of floorWithHouseNoArr) {
              for (const i of tempUnitArr) {
                this.total.houseNo_S_Count += 1
                // this.total.S_Count += 1
                list.push({
                  spaceCode: i,
                  houseNo: n
                })
              }
            }
            for (const item of list) {
              await this.addHouse(item.spaceCode, item.houseNo)
              await this.sleep(500)
              this.total.S_Count += 1
            }

            // floorWithHouseNoArr.map(async n => {
            //   tempUnitArr.map(async i => {
            //     // setTimeout(() => {
            //     this.total.houseNo_S_Count += 1
            //     this.total.S_Count += 1
            //     this.addHouse(i, n)
            //     // }, 1000)
            //   })
            // })

            // await Promise.all(floorWithHouseNoArr.map(async n=>{
            //   await Promise.all(tempUnitArr.map( async i=>{
            //     setTimeout(async ()=> {
            //       this.total.houseNo_S_Count += 1;
            //       await this.addHouse(i, n);
            //     },500)
            //   }));
            // }));
          }
        }
      }
    },
    // 添加楼栋
    async addBuilding(spaceCode, buildingName, buildingNo) {
      try {
        let params = { spaceCode, buildingName, buildingNo }
        let result = await this.$apis.ODC('添加楼栋', params)
        if (this.$apis.isOk(result)) {
          return result.spaceCode
        }
      } catch (err) {}
    },
    // 添加单元
    async addUnit(spaceCode, unitName, unitNo) {
      try {
        let params = { spaceCode, unitName, unitNo }
        let result = await this.$apis.ODC('添加单元', params)
        if (this.$apis.isOk(result)) {
          return result.spaceCode
        }
      } catch (err) {}
    },
    // 添加房间
    async addHouse(spaceCode, houseNo) {
      try {
        let params = { spaceCode, houseNo, jzmj: Number(this.form.jzmj), fwzl: this.form.fwzl }
        let result = await this.$apis.ODC('添加房屋', params)
        if (this.$apis.isOk(result)) {
          return result.spaceCode
        }
      } catch (err) {}
    },
    sleep(time) {
      return new Promise(function(resolve) {
        setTimeout(resolve, time)
      })
    },
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await this.submit()
        } else {
          return false
        }
      })
    },
    // 提交事件
    async submit() {
      let el = event.currentTarget
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el)
      // 判断下操作
      this.isShowForm = true
      await this.batchData(this.form)
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el)
      }, 1000)
    },
    // 关闭弹框回调
    handleClose(done, isRefresh = false) {
      this.isVisible = false
      if (this.isVisible == false) {
        isRefresh = true
        this.emptyData()
        this.$emit('handleDiaLogClose', isRefresh, 'batch')
      }
    },
    // 清空数据
    emptyData() {
      this.isShowForm = false
      this.checkRadio = 1
      this.form.spaceCode = ''
      this.form.buildingStartNo = ''
      this.form.buildingEndNo = ''
      this.form.unitStartNo = ''
      this.form.unitEndNo = ''
      this.form.floorStartNo = ''
      this.form.floorEndNo = ''
      this.form.houseStartNo = ''
      this.form.houseStartEnd = ''
      this.total = {
        E_Count: 0,
        buildNo_E_Count: 0,
        unitNo_E_Count: 0,
        houseNo_E_Count: 0,
        S_Count: 0,
        buildNo_S_Count: 0,
        unitNo_S_Count: 0,
        houseNo_S_Count: 0,
        percentage: 0
      }
    }
  },
  destroyed() {
    this.$cFun.changeNavBarShadow()
  },
  components: {}
}
</script>

<style lang="scss" scope="scope">
.BatchWidth {
  width: 50%;
  min-width: 300px;
}
</style>
