<template>
  <el-dialog
    customClass="HouseDialogCURD"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-30">{{ dialogTitle }}</h5>
      <vs-divider></vs-divider>
    </span>

    <!-- 弹窗内容 -->
    <el-form class="common-form" :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
      <!-- 表单认证(房间号) -->
      <el-form-item label="房间号" prop="houseNo">
        <el-input v-model="form.houseNo"
        prefix-icon="el-icon-user" placeholder="请输入房间号"  autocomplete="off" :disabled="isDisable1"></el-input>
      </el-form-item>

      <el-form-item label="建筑面积" prop="jzmj">
        <el-input v-model="form.jzmj"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'')" 
          prefix-icon="el-icon-user" placeholder="请输入建筑面积"  autocomplete="off" :disabled="isDisable"></el-input>
      </el-form-item>

      <el-form-item label="房屋坐落" prop="fwzl">
        <el-input v-model="form.fwzl"
        prefix-icon="el-icon-user" placeholder="请输入房屋坐落"  autocomplete="off" :disabled="isDisable"></el-input>
      </el-form-item>

    </el-form>
    <!-- 弹窗底部 -->
    <span slot="footer" class="dialog-footer">
      <vs-divider></vs-divider>
      <vs-button
        size="small"
        class="mr-5"
        type="relief"
        color="grey"
        @click="handleClose"
      >
        取 消
      </vs-button>
      <vs-button
        size="small"
        type="relief"
        color="danger"
        class="mr-5 vs-con-loading__container"
        @click="delBtn"
        v-if="isDisable && this.operate == 'DELETE'"
      >
        删除
      </vs-button>
      <vs-button
        size="small"
        type="relief"
        color="primary"
        :disabled="!validateForm"
        class="vs-con-loading__container"
        @click="submitForm('ruleForm')"
        v-if="!isDisable"
      >
        确 定
      </vs-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["dialogFormVisible", "dialogFormData", "dialogFormOpt"],
  data() {
    return {
      reFresh: true,
      dialogTitle: "",
      isVisible: this.dialogFormVisible,
      operate: this.dialogFormOpt,
      isDisable: false,
      isDisable1: false,
      isShowCascader: false,
      row: {},
      form: {
        spaceCode: "",
        houseID: "",
        houseNo: "",
        jzmj: "",
        fwzl: ""
      },
      rules: {
        houseNo: [
          { required: true, message: '请输入房间号', trigger: 'blur' },
          // {
          //   pattern: /^[+]?(0|([1-9]\d*))?$/,
          //   message: "请输入正确数字",
          //   trigger: "blur"
          // },
        ],
        jzmj: [
          { required: true, message: '请输入建筑面积', trigger: 'blur' },
          {
            pattern: /^[+-]?(0|([1-9]\d*))(\.\d{1,3})?$/,
            message: "请输入正确建筑面积,最多三位小数",
            trigger: "blur"
          },
        ],
        fwzl: [
          { required: true, message: '请输入房屋坐落', trigger: 'blur' },
        ],
      }
    };
  },
  computed: {
    // 监听表单认证状况
    validateForm() {
      let isValid = false;
      switch (this.operate) {
        case "CREATE":
          isValid =
            !this.$cFun.isNullOrEmptyForObJ({
              spaceCode: this.form.spaceCode,
              houseNo: this.form.houseNo,
              jzmj: this.form.jzmj,
              fwzl: this.form.fwzl
            });
          break;
        case "READ":
          break;
        case "UPDATE":
          isValid =
            !this.$cFun.isNullOrEmptyForObJ({
              spaceCode: this.form.spaceCode,
              houseNo: this.form.houseNo,
              jzmj: this.form.jzmj,
              fwzl: this.form.fwzl
            });
          break;
        case "DELETE":
          break;
      }
      return isValid;
    }
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      this.reFresh = false;
      // row
      this.row = val.row;
      this.form.spaceCode = val.house_Code;
      this.form.houseID = val.house_ID;
      this.form.houseNo = val.house_No;
      this.form.jzmj = val.jzmj;
      this.form.fwzl = val.fwzl;
      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true;
      });
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.$cFun.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    },
    // 监听父组件传回来的值 - CURD操作值
    dialogFormOpt(val) {
      this.operate = this.dialogFormOpt;
      switch (val) {
        case "CREATE":
          this.dialogTitle = "创建房间";
          this.isDisable = false;
          this.isDisable1 = false;
          this.isShowCascader = true;
          break;
        case "READ":
          this.dialogTitle = "查看房间";
          this.isDisable = true;
          this.isDisable1 = true;
          this.isShowCascader = false;
          break;
        case "UPDATE":
          this.dialogTitle = "更新房间";
          this.isDisable = false;
          this.isDisable1 = true;
          this.isShowCascader = false;
          break;
        case "DELETE":
          this.dialogTitle = "删除房间";
          this.isDisable = true;
          this.isDisable1 = true;
          this.isShowCascader = false;
          break;
      }
    }
  },
  methods: {
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget;
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          await this.submit();
        } else {
          return false;
        }
      })
    },
    // 提交事件
    async submit() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el);
      // 判断下操作
      if (this.operate === "CREATE") {
        await this.addHouse();
      } else if (this.operate === "UPDATE") {
        await this.updateHouse();
      }
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 删除事件
    async delBtn() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el, "danger");
      // 提交API删除
      await this.delHouse();
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 添加房屋
    async addHouse() {
      try {
        let n = Number(this.form.houseNo);
        let params = {
          spaceCode: this.form.spaceCode,
          houseNo: ( n>= 1000 && n < 9999) ? `${n}` : ( n>= 100 && n < 999) ? `0${n}` : ((n >= 10 && n < 100) ? `00${n}` : `000${n}`),
          // houseNo: ( n>= 1000 && n < 9999) ? `${n}` : ( n>= 100 && n < 999) ? `${n}` : ((n >= 10 && n < 100) ? `0${n}` : `00${n}`),
          jzmj: Number(this.form.jzmj),
          fwzl: this.form.fwzl,
        };
        let result = await this.$apis.ODC("添加房屋", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 更新房间
    async updateHouse() {
      console.log(this.form)
      try {
        let params = {
          houseID: this.form.houseID,
          jzmj: Number(this.form.jzmj),
          fwzl: this.form.fwzl
        };
        console.log(params);
        let result = await this.$apis.ODC("修改房屋", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 删除房屋
    async delHouse() {
      try {
        let params = {
          houseID: this.form.houseID
        };
        let result = await this.$apis.ODC("删除房屋", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 表单校验
    isValidForm() {
      return new Promise((resolve, reject) => {
        const isValid = this.$validator.validateAll();
        isValid
          .then(valid => {
            resolve(valid);
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    // 关闭弹框回调
    handleClose(done, isRefresh = false) {
      this.isVisible = false;
      if (this.isVisible == false) {
        this.emptyData();
        this.$emit("handleDiaLogClose", isRefresh, 'house', this.row);
      }
    },
    // 清空数据
    emptyData() {
      this.form.spaceCode = "";
      this.form.houseNo = "";
      this.form.jzmj = "";
      this.form.fwzl = "";
    }
  },
  components: {}
};
</script>

<style lang="scss" scope="scope">
.HouseDialogCURD {
  width: 35%;
  min-width: 300px;
}
</style>
