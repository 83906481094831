<!-- eslint-disable prettier/prettier -->
<!-- 
   页面  楼栋列表 (这里的Table 用了 elemnt-ui)
-->
<template>
  <div id="collect-buildingList-page" class="data-list-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <!-- 操作按钮 -->
        <div class="operate-container mb-3">
          <vs-button size="small" type="relief" class="mr-4" @click="$router.back(-1)">返回</vs-button>
          <vs-button size="small" type="relief" class="mr-4" @click="createBuilding">新增楼栋</vs-button>
          <vs-button size="small" type="relief" @click="createBatch">批量生成</vs-button>
        </div>
        <!-- 整体表 -->
        <el-table v-if="isRefresh0" class="table-theme-space cursor-pointer" :data="buildings" ref="buildingsTable" v-loading="loading" row-key="space_code" @row-click="toogleExpand">
          <!-- <el-table-column width="50" show-overflow-tooltip header-align="center" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.space_code==currentIndex">
                <i class="el-icon-arrow-down"></i>
              </span>
              <span v-else>
                <i class="el-icon-arrow-right"></i>
              </span>
            </template>
          </el-table-column> -->
          <el-table-column type="index" width="50" label="序号" header-align="center" align="center">
            <template slot-scope="scope">
              <span>{{ (pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="楼栋名称" property="building_name" min-width="120" show-overflow-tooltip header-align="center" align="center"></el-table-column>
          <el-table-column label="单元名称" min-width="120" show-overflow-tooltip header-align="center" align="center">
            <i class="el-icon-minus"></i>
          </el-table-column>
          <el-table-column label="房间号" min-width="120" show-overflow-tooltip header-align="center" align="center">
            <i class="el-icon-minus"></i>
          </el-table-column>
          <el-table-column label="建筑面积" min-width="120" show-overflow-tooltip header-align="center" align="center">
            <i class="el-icon-minus"></i>
          </el-table-column>
          <el-table-column label="房屋坐落" min-width="120" show-overflow-tooltip header-align="center" align="center">
            <i class="el-icon-minus"></i>
          </el-table-column>
          <el-table-column label="登记人数" min-width="120" show-overflow-tooltip header-align="center" align="center">
            <i class="el-icon-minus"></i>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" class="primary" @click="createUnit(scope.row)">新增单元</el-button>
              <!-- <el-button type="text" size="small" class="primary" @click="updateBuilding(scope.row)">编辑</el-button>        
              <el-button type="text" size="small" class="primary" @click="delData(scope.row)">删除</el-button> -->
            </template>
          </el-table-column>
          <!-- 二级表格 -->
          <el-table-column type="expand" width="0">
            <template slot-scope="scope" v-if="isRefresh">
              <span v-if="scope.row.unitArr && scope.row.unitArr.length > 0">
                <span v-for="item in scope.row.unitArr" :key="item.space_code" v-bind:class="['unit-list', { unitClass: item.space_code == currentUnitIndex }]">
                  <div v-if="isRefresh">
                    <div class="flex items-center" @click="toogleUnitExpand(item)">
                      <div class="unit-list-item">
                        <span v-if="item.space_code == currentUnitIndex">
                          <i class="el-icon-arrow-down"></i>
                        </span>
                        <span v-else>
                          <i class="el-icon-arrow-right"></i>
                        </span>
                      </div>
                      <div class="unit-list-item"><i class="el-icon-minus"></i></div>
                      <div class="unit-list-item">
                        <span>{{ item.unit_name }}</span>
                      </div>
                      <div class="unit-list-item"><i class="el-icon-minus"></i></div>
                      <div class="unit-list-item"><i class="el-icon-minus"></i></div>
                      <div class="unit-list-item"><i class="el-icon-minus"></i></div>
                      <div class="unit-list-item"><i class="el-icon-minus"></i></div>
                      <div class="unit-list-item">
                        <el-button type="text" size="small" class="primary" @click="createHouse(item)">新增房间</el-button>
                        <!-- <el-button type="text" size="small" class="primary" @click="updateUnit(item)" >编辑</el-button>        
                        <el-button type="text" size="small" class="primary" @click="delUnit(item)">删除</el-button> -->
                      </div>
                    </div>
                    <!-- 三级表格 -->
                    <span>
                      <div class="houseClass" style="display:none" v-for="item1 in item.houseArr" :key="item1.space_code">
                        <div v-if="isRefresh1">
                          <div class="flex items-center">
                            <div class="unit-list-item"></div>
                            <div class="unit-list-item"><i class="el-icon-minus"></i></div>
                            <div class="unit-list-item"><i class="el-icon-minus"></i></div>
                            <div class="unit-list-item">
                              <span>{{ item1.house_no }}</span>
                            </div>
                            <div class="unit-list-item">
                              <span v-if="item1.third_extend.jzmj">
                                <span>{{ item1.third_extend.jzmj }}</span>
                              </span>
                              <span v-else>
                                <i class="el-icon-minus"></i>
                              </span>
                            </div>
                            <div class="unit-list-item">
                              <span v-if="item1.third_extend.fwzl">
                                <span>{{ item1.third_extend.fwzl }}</span>
                              </span>
                              <span v-else>
                                <i class="el-icon-minus"></i>
                              </span>
                            </div>
                            <div class="unit-list-item"><i class="el-icon-minus"></i></div>
                            <div class="unit-list-item">
                              <!-- <el-button type="text" size="small" class="primary" disabled="disabled">房间详情</el-button> -->
                              <el-button type="text" size="small" class="primary" @click="updateHouse(item1)">编辑</el-button>        
                              <el-button type="text" size="small" class="primary" @click="delHouse(item1)">删除</el-button>

                              <!-- <el-button type="text" size="small" class="primary">房间详情</el-button>
                              <el-button type="text" size="small" class="primary">编辑</el-button>
                              <el-button type="text" size="small" class="primary" @click="delHouse(item1)">删除</el-button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                    <span v-if="item.houseArr && item.houseArr.length == 0 && isRefresh1">
                      <div class="houseClass p-4 text-center" style="display:none; border-top: 1px solid #EBEEF5;"><p class="font-semibold">暂无房间数据</p></div>
                    </span>
                  </div>
                </span>
              </span>
              <span v-else>
                <div class="p-4 text-center"><p class="font-semibold">暂无单元数据</p></div>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="bottom mt-5 text-center">
          <el-pagination class="pagination" layout="total, sizes, prev, pager, next" prev-text="上一页" next-text="下一页" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageOpt.currentPage" :page-sizes="pageOpt.pageSizes" :page-size="pageOpt.currentPageSize" :pager-count="pageOpt.pageCount" :total="pageOpt.totalPageSize" :hide-on-single-page="pageOpt.hideSinglePage"></el-pagination>
        </div>
      </div>
    </vx-card>

    <!-- 弹出框 -->
    <buildingDialogCURD :dialogFormOpt="dialogFormOpt" :dialogFormData="dialogFormData" :dialogFormVisible="dialogFormVisible" @handleDiaLogClose="handleDiaLogClose(arguments)"></buildingDialogCURD>
    <unitDialogCURD :dialogFormOpt="dialogFormOpt" :dialogFormData="dialogFormData" :dialogFormVisible="unitDialogFormVisible" @handleDiaLogClose="handleDiaLogClose(arguments)"></unitDialogCURD>
    <houseDialogCURD :dialogFormOpt="dialogFormOpt" :dialogFormData="dialogFormData" :dialogFormVisible="houseDialogFormVisible" @handleDiaLogClose="handleDiaLogClose(arguments)"></houseDialogCURD>
    <batch :dialogFormOpt="dialogFormOpt" :dialogFormData="dialogFormData" :dialogFormVisible="batchDialogFormVisible" @handleDiaLogClose="handleDiaLogClose(arguments)"></batch>
  </div>
</template>

<script>
// 导入弹框
import batch from './Batch'
import buildingDialogCURD from './BuildingDialogCURD'
import unitDialogCURD from './UnitDialogCURD'
import houseDialogCURD from './HouseDialogCURD'

export default {
  data() {
    return {
      // 选中数据
      selected: [],
      // 渲染数据
      buildings: [],
      villageCode: '',
      // 加载状态
      loading: false,
      currentIndex: null,
      currentUnitIndex: null,
      currentBuildCode: null,
      hiddenHouse: false,
      hiddenUnit: false,
      isRefresh0: true,
      isRefresh: false,
      isRefresh1: false,
      // 弹出框状态
      dialogFormVisible: false,
      unitDialogFormVisible: false,
      houseDialogFormVisible: false,
      batchDialogFormVisible: false,
      // 弹出框所需数据
      dialogFormData: {},
      // 弹出框所做的操作: CRUD
      dialogFormOpt: '',
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      }
    }
  },
  methods: {
    // 批量生成
    createBatch() {
      this.dialogFormOpt = this.$CONST.OPTRATE.C
      this.batchDialogFormVisible = true
      this.dialogFormData = Object.assign({
        village_code: this.villageCode
      })
    },
    // 新增楼栋
    createBuilding() {
      this.dialogFormOpt = this.$CONST.OPTRATE.C
      this.dialogFormVisible = true
      this.dialogFormData = Object.assign({
        village_code: this.villageCode
      })
    },
    // 新增单元
    createUnit(row) {
      this.hiddenUnit = true
      this.dialogFormOpt = this.$CONST.OPTRATE.C
      this.unitDialogFormVisible = true
      this.dialogFormData = Object.assign({
        building_Code: row.space_code,
        row: row
      })
    },
    // 新增房间
    createHouse(row) {
      this.hiddenHouse = true
      this.dialogFormOpt = this.$CONST.OPTRATE.C
      this.houseDialogFormVisible = true
      this.dialogFormData = Object.assign({
        house_Code: row.space_code,
        row: row
      })
    },
    // 编辑楼栋
    updateBuilding(row) {
      this.hiddenUnit = true
      this.dialogFormOpt = this.$CONST.OPTRATE.U
      this.dialogFormVisible = true
      this.dialogFormData = Object.assign({
        village_code: this.villageCode,
        building_no: row.building_no,
        building_id: row.building_id,
        building_name: row.building_name,
        row: row
      })
    },
    // 编辑单元
    updateUnit(row) {
      this.hiddenHouse = true
      this.dialogFormOpt = this.$CONST.OPTRATE.U
      this.unitDialogFormVisible = true
      this.dialogFormData = Object.assign({
        building_Code: row.space_code,
        unitName: row.unit_name,
        unitNo: row.unit_no,
        unitID: row.unit_id,
        row: row
      })
    },
    // 删除楼栋
    delData(row) {
      this.hiddenUnit = true
      this.dialogFormOpt = this.$CONST.OPTRATE.D
      this.dialogFormVisible = true
      this.dialogFormData = Object.assign({
        village_code: this.villageCode,
        building_no: row.building_no,
        building_id: row.building_id,
        building_name: row.building_name,
        row: row
      })
    },
    // 删除单元
    delUnit(row) {
      this.hiddenHouse = true
      this.dialogFormOpt = this.$CONST.OPTRATE.D
      this.unitDialogFormVisible = true
      this.dialogFormData = Object.assign({
        building_Code: row.space_code,
        unitName: row.unit_name,
        unitNo: row.unit_no,
        unitID: row.unit_id,
        row: row
      })
    },
    // 编辑房间
    updateHouse(row) {
      console.log(row)
      this.hiddenHouse = true
      this.dialogFormOpt = this.$CONST.OPTRATE.U
      this.houseDialogFormVisible = true
      this.dialogFormData = Object.assign({
        house_Code: row.space_code,
        house_ID: row.house_id,
        house_No: row.house_no,
        jzmj: row.third_extend.jzmj,
        fwzl: row.third_extend.fwzl,
        row: row
      })
    },
    // 删除房间
    delHouse(row) {
      this.hiddenHouse = true
      this.dialogFormOpt = this.$CONST.OPTRATE.D
      this.houseDialogFormVisible = true
      this.dialogFormData = Object.assign({
        house_Code: row.space_code,
        house_ID: row.house_id,
        house_No: row.house_no,
        jzmj: row.third_extend.jzmj,
        fwzl: row.third_extend.fwzl,
        row: row
      })
    },
    // 列表序号
    rowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1 + (this.pageOpt.currentPage - 1) * this.pageOpt.currentPageSize
    },
    // 列表选中
    handleSelectionChange(val) {
      this.selected = val
      // console.log("角色列表选中:", this.selected);
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val
      this.getBuildingList()
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val
      this.getBuildingList()
    },
    // 加载动画
    loadAnimation() {
      this.loading = true
      return new Promise(resolve => {
        setTimeout(() => {
          this.loading = false
          resolve(true)
        }, 1000)
      })
    },
    // 当子模态框关闭回调
    handleDiaLogClose(arr) {
      this.dialogFormVisible = false
      this.unitDialogFormVisible = false
      this.houseDialogFormVisible = false
      this.batchDialogFormVisible = false

      this.hiddenUnit = false
      this.hiddenHouse = false

      let isRefresh = arr[0]
      let type = arr[1]
      let row = arr[2]

      if (type == 'building' && isRefresh) this.toogle(row)
      if (type == 'unit' && isRefresh) this.toogleExpand(row, true)
      if (type == 'house' && isRefresh) this.toogleUnitExpand(row, true)
      if (type == 'batch' && isRefresh) this.toogle()
    },
    // 请求首层数据
    async toogle(row) {
      // 关闭下级
      if (row) this.$refs.buildingsTable.toggleRowExpansion(row, false)
      // 清除锚点
      this.hiddenUnit = false
      this.hiddenHouse = false
      this.currentIndex = null
      this.currentUnitIndex = null

      // 请求数据
      await this.getBuildingList()
      // 刷新
      this.isRefresh0 = false
      this.$nextTick(() => {
        this.isRefresh0 = true
      })
    },
    // 请求下层数据
    async toogleExpand(row, isCallback = false) {
      // 存储下级所需数据
      this.currentBuildCode = this.$cFun.isNullOrEmpty(row.building_code) ? row.space_code : row.building_code
      // 获取数据
      let code = this.$cFun.isNullOrEmpty(row.building_code) ? row.space_code : row.building_code
      if (this.hiddenUnit) return false
      let $table = this.$refs.buildingsTable
      let currentIndex = null
      // 匹配当前行
      this.buildings.map((item, index) => {
        if (code != item.space_code) {
          $table.toggleRowExpansion(item, false)
        } else {
          currentIndex = index
        }
      })
      // 请求人房信息
      this.buildings[currentIndex]['unitArr'] = (await this.getUnitList(code)) || []
      if (typeof isCallback == 'object') isCallback = false
      if (!isCallback) $table.toggleRowExpansion(row)
      // console.log(this.buildings);
      // 存储当前的锚点
      if (code == this.currentIndex) {
        this.currentIndex = null
        this.currentUnitIndex = null
      } else {
        this.currentIndex = code
        this.currentUnitIndex = null
      }
      // 刷新
      this.isRefresh = false
      this.$nextTick(() => {
        this.isRefresh = true
      })
    },
    // 请求单元下层数据 (因为触发2种层级都会可能，数据会不同)
    async toogleUnitExpand(row, isCallback = false) {
      if (this.hiddenHouse) return false
      let tempIndex = null
      let tempIndex1 = null
      let currentBuildingCode = this.$cFun.isNullOrEmpty(row.building_code) ? this.currentBuildCode : row.building_code
      let currentUnitCode = this.$cFun.isNullOrEmpty(row.building_code) ? row.unit_code : row.space_code
      // console.log(currentBuildingCode, currentUnitCode);
      // 匹配当前行
      this.buildings.map((item, index) => {
        if (currentBuildingCode == item.space_code) {
          item['unitArr'].map((item1, index1) => {
            if (currentUnitCode == item1.space_code) {
              tempIndex = index
              tempIndex1 = index1
            }
          })
        }
      })
      // 添加数据
      // console.log('tempIndex:',tempIndex, 'tempIndex1:', tempIndex);
      if (tempIndex != null && tempIndex1 != null) {
        this.buildings[tempIndex]['unitArr'][tempIndex1]['houseArr'] = (await this.getHouseList(currentUnitCode)) || []
      }
      // 存储当前的锚点
      if (currentUnitCode == this.currentUnitIndex && !isCallback) {
        this.currentUnitIndex = null
      } else {
        this.currentUnitIndex = currentUnitCode
      }
      // 刷新
      this.isRefresh1 = false
      this.$nextTick(() => {
        this.isRefresh1 = true
      })
    },
    // 获取楼栋列表
    async getBuildingList() {
      this.loading = true
      try {
        let params = {
          villageCode: this.villageCode,
          pageNum: this.pageOpt.currentPage,
          pageSize: this.pageOpt.currentPageSize
        }
        let result = await this.$apis.ODC('获取楼栋列表', params)
        if (this.$apis.isOk(result)) {
          this.buildings = result.data.buildingList
          this.pageOpt.totalPageSize = result.data.count
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
      this.loading = false
    },
    // 获取单元列表
    async getUnitList(buildingCode) {
      try {
        let params = {
          buildingCode: buildingCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取单元列表', params)
        if (this.$apis.isOk(result)) {
          return result.data.unitList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取房间列表
    async getHouseList(unitCode) {
      try {
        let params = {
          unitCode: unitCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取房屋列表', params)
        if (this.$apis.isOk(result)) {
          return result.data.houseList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    }
  },
  created() {
    // 获取小区code
    this.villageCode = this.$route.params.id
    // 请求数据
    this.getBuildingList()
  },
  components: {
    buildingDialogCURD,
    unitDialogCURD,
    houseDialogCURD,
    batch
  }
}
</script>

<style lang="scss" scope="scope">
#collect-buildingList-page {
  .table_height_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
  }
  .el-button.is-disabled.el-button--text {
    text-decoration: line-through;
  }
  .table-theme-space {
    .el-table__expanded-cell {
      padding: 0px;
      .unit-list {
        display: block;
        &:not(:last-child) {
          border-bottom: 1px solid #ebeef5;
        }
        .unit-list-item {
          display: inline-block;
          text-align: center;
          padding: 5px 0;
          &:first-child {
            width: 50px;
          }
          &:nth-child(2) {
            flex: 1;
            min-width: 120px;
          }
          &:nth-child(3) {
            flex: 1;
            min-width: 120px;
          }
          &:nth-child(4) {
            flex: 1;
            min-width: 120px;
          }
          &:nth-child(5) {
            flex: 1;
            min-width: 120px;
          }
          &:nth-child(6) {
            flex: 1;
            min-width: 120px;
          }
          &:nth-child(7) {
            flex: 1;
            min-width: 120px;
          }
          &:last-child {
            width: 150px;
          }
        }
      }
    }
  }
  .unitClass {
    .houseClass {
      display: block !important;
    }
  }
  .is-hidden {
    // display: none;
  }
}
</style>
